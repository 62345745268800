import i18n from '@/locales/index'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import * as Sentry from '@sentry/browser'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '@/views/homeV2/index.vue'),
    meta: {
      title: i18n.global.t('route.home')
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/login/index.vue'),
    meta: {
      title: i18n.global.t('route.login')
    }
  },
  {
    path: '/priv',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '@/views/privacy/index.vue'),
    meta: {
      title: i18n.global.t('route.privacy')
    }
  },
  {
    path: '/userve',
    name: 'Userve',
    component: () => import(/* webpackChunkName: "Userve" */ '@/views/userve/index.vue'),
    meta: {
      title: i18n.global.t('route.userve')
    }
  },
  {
    path: '/quick-guide',
    name: 'QuickGuide',
    component: () => import(/* webpackChunkName: "QuickGuide" */ '@/views/active-page/quickGuide/index.vue'),
    meta: {
      title: i18n.global.t('route.quickGuide')
    }
  },
  {
    path: '/room-rewards',
    name: 'RoomRewards',
    component: () => import(/* webpackChunkName: "RoomRewards" */ '@/views/active-page/roomRewards/index.vue'),
    meta: {
      title: i18n.global.t('route.roomRewards')
    }
  },
  {
    path: '/update-content',
    name: 'UpdateContent',
    component: () => import(/* webpackChunkName: "UpdateContent" */ '@/views/active-page/updateContent/index.vue'),
    meta: {
      title: i18n.global.t('route.updateContent')
    }
  },
  {
    path: '/friend-space',
    name: 'FriendSpace',
    component: () => import(/* webpackChunkName: "FriendSpace" */ '@/views/active-page/friendSpace/index.vue'),
    meta: {
      title: i18n.global.t('route.friendSpace')
    }
  },
  {
    path: '/user-group',
    name: 'UserGroup',
    component: () => import(/* webpackChunkName: "UserGroup" */ '@/views/active-page/userGroup/index.vue'),
    meta: {
      title: i18n.global.t('route.userGroup')
    }
  },
  {
    path: '/quick-start-guide',
    name: 'QuickStartGuide',
    component: () => import(/* webpackChunkName: "QuickStartGuide" */ '@/views/active-page/quickStartGuide/index.vue'),
    meta: {
      title: i18n.global.t('route.quickStartGuide')
    },
    children: [
      {
        path: 'reward',
        name: 'Reward',
        component: () => import(/* webpackChunkName: "Reward" */ '@/views/active-page/quickStartGuide/index.vue'),
        meta: {
          title: i18n.global.t('route.quickStartGuide')
        }
      }
    ]
  },
  {
    path: '/ktv-party',
    name: 'KtvParty',
    component: () => import(/* webpackChunkName: "KtvParty" */ '@/views/active-page/ktvParty/index.vue'),
    meta: {
      title: i18n.global.t('route.ktvParty')
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '',
    component: () => import(/* webpackChunkName: "error-page-404" */'@/views/error-page/404.vue'),
    meta: {
      title: '404',
      hidden: true,
      hideBreadcrumb: true
    }
  },
  {
    path: '/music',
    name: 'Music',
    component: () => import(/* webpackChunkName: "Music" */ '@/views/music/music.vue'),
    meta: {
      title: i18n.global.t('route.music')
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "About" */ '@/views/about/about.vue'),
    meta: {
      title: i18n.global.t('route.about')
    }
  },
  {
    path: '/upload',
    name: 'Upload',
    component: () => import(/* webpackChunkName: "Upload" */ '@/views/music/uploadMusic.vue'),
    meta: {
      title: i18n.global.t('route.upload')
    }
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import(/* webpackChunkName: "Recharge" */ '@/views/recharge/index.vue'),
    meta: {
      title: i18n.global.t('route.recharge')
    }
  },
  {
    path: '/udesk',
    name: 'Udesk',
    component: () => import(/* webpackChunkName: "About" */ '@/views/udesk/index.vue'),
    meta: {
      title: i18n.global.t('route.udesk')
    }
  }
]
// const router = createRouter({
//   history: createWebHistory(),
//   routes
// })
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 }
  }
})

// 重置路由
export async function resetRouter() {
  router.getRoutes().forEach((route) => {
    const { name } = route
    router.hasRoute(name as string) && router.removeRoute(name as string)
  })
}

export default router
